import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

export interface ChevronDownProps extends SvgIconProps {
  height?: number | string;
  width?: number | string;
  fill?: string;
}

const ChevronDown = ({ width = 12, height = 7, fill = "#97A0AA", ...props }: ChevronDownProps) => (
  <SvgIcon {...props} viewBox={`0 0 ${width} ${height}`} style={{ height, width }}>
    <g id="Dashboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Verifier-Initiates-Request_Dashboard---"
        transform="translate(-1398.000000, -49.000000)"
        fill={fill}
        fillRule="nonzero"
      >
        <g id="Path" transform="translate(1398.000000, 49.000000)">
          <path
            d="M5.99998008,7 C5.78491566,7 5.56987779,6.91596701 5.40591042,6.74825433 L0.246170155,1.46803629
              C-0.0820567184,1.13214891 -0.0820567184,0.587565105 0.246170155,0.251813616 C0.57426424,-0.0839378721
              1.10632162,-0.0839378721 1.43457505,0.251813616 L5.99998008,4.92404262 L10.5654117,0.251976682
              C10.8936385,-0.0837748068 11.4256428,-0.0837748068 11.7537103,0.251976682 C12.0820966,0.58772817
              12.0820966,1.13231198 11.7537103,1.46819935 L6.59404974,6.74841739 C6.4300027,6.91615725 6.21496483,7
              5.99998008,7 Z"
          />
        </g>
      </g>
    </g>
  </SvgIcon>
);

export default ChevronDown;
