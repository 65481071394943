import React from "react";

const SignOutIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2155_11622)">
        <mask id="mask0_2155_11622" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
          <path
            d="M9.5 19.5H6.16667C5.72464 19.5 5.30072 19.3244 4.98816 19.0118C4.67559 18.6993 4.5 18.2754 4.5 17.8333V6.16667C4.5 5.72464 4.67559 5.30072 4.98816 4.98816C5.30072 4.67559 5.72464 4.5 6.16667 4.5H9.5"
            stroke="#898FA7"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M15.3334 16.1668L19.5 12.0002L15.3334 7.8335"
            stroke="#898FA7"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path d="M19.5 12H9.5" stroke="#898FA7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </mask>
        <g mask="url(#mask0_2155_11622)">
          <rect width="24" height="24" fill="#666C88" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2155_11622">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SignOutIcon;
