import React from "react";

const UserIconOutlined = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2136_11832)">
        <mask id="mask0_2136_11832" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
          <path
            d="M18.6667 19.5V17.8333C18.6667 16.9493 18.3155 16.1014 17.6904 15.4763C17.0653 14.8512 16.2174 14.5 15.3334 14.5H8.66671C7.78265 14.5 6.93481 14.8512 6.30968 15.4763C5.68456 16.1014 5.33337 16.9493 5.33337 17.8333V19.5"
            stroke="#898FA7"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12 11.1667C13.8409 11.1667 15.3333 9.67428 15.3333 7.83333C15.3333 5.99238 13.8409 4.5 12 4.5C10.159 4.5 8.66663 5.99238 8.66663 7.83333C8.66663 9.67428 10.159 11.1667 12 11.1667Z"
            stroke="#898FA7"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </mask>
        <g mask="url(#mask0_2136_11832)">
          <rect width="24" height="24" fill="#666C88" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2136_11832">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default UserIconOutlined;
