import { Typography } from "@material-ui/core";
import Image from "next/image";
import React, { FC, ReactNode } from "react";

import styles from "@/components/views/MaintenanceView/MaintenanceView.module.css";

type ViewComponentProps = {
  children?: ReactNode;
};

const ViewComponent: FC<ViewComponentProps> = ({}) => {
  return (
    <div className={styles.root}>
      <Image
        width={120}
        height={120}
        src="/scheduled-maintenance.svg"
        alt="Scheduled Maintenance icon"
        className={styles.image}
      />
      <Typography variant="h3" align="center">
        Scheduled Maintenance:
      </Typography>
      <Typography variant="h3" align="center" gutterBottom>
        Please Try Again later
      </Typography>
      <div className={styles.textContainer}>
        <Typography variant="body1" align="center" color="textSecondary">
          Our system is undergoing planned maintenance as we continually improve Konfir and is temporarily unavailable.
        </Typography>
        <Typography variant="body1" align="center" color="textSecondary">
          Please try again in 2 hours when the maintenance is expected to be completed. We appreciate your
          understanding!
        </Typography>
      </div>
    </div>
  );
};

export default ViewComponent;
