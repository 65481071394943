import React, { FC, ReactNode } from "react";

import MainLayout from "@/components/layouts/MainLayout/MainLayout";
import ViewComponent from "@/components/views/MaintenanceView/ViewComponent";

type MaintenanceViewProps = {
  children?: ReactNode;
};

const MaintenanceView: FC<MaintenanceViewProps> = ({}) => {
  return (
    <MainLayout>
      <ViewComponent />
    </MainLayout>
  );
};

export default MaintenanceView;
