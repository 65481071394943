import React from "react";

const KonfirSmallIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2155_11613)">
        <mask id="mask0_2155_11613" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
          <path
            d="M18.1284 5.36435C18.7564 4.73624 18.7564 3.71786 18.1284 3.08974C17.5003 2.46162 16.482 2.46162 15.854 3.08974L8.84575 10.091C8.76132 10.1754 8.61696 10.1156 8.61696 9.99623V7.88518C8.61696 6.99689 7.89694 6.27679 7.00874 6.27679C6.12054 6.27679 5.40051 6.99689 5.40051 7.88518V14.2028C5.40051 14.8534 5.79234 15.4398 6.3933 15.6888C6.99425 15.9377 7.68598 15.8001 8.14593 15.3401L18.1284 5.36435Z"
            fill="url(#paint0_linear_2155_11613)"
          />
          <path
            d="M13.5636 14.8561C13.0404 14.0485 11.9616 13.8179 11.1541 14.3412C10.3466 14.8645 10.1161 15.9434 10.6393 16.751L13.1114 20.5668C13.6346 21.3745 14.7134 21.605 15.5209 21.0817C16.3285 20.5584 16.5589 19.4795 16.0357 18.6719L13.5636 14.8561Z"
            fill="#151E49"
          />
          <path
            d="M8.61696 19.7729C8.61696 20.6612 7.89694 21.3813 7.00874 21.3813C6.12054 21.3813 5.40051 20.6612 5.40051 19.7729C5.40051 18.8846 6.12054 18.1645 7.00874 18.1645C7.89694 18.1645 8.61696 18.8846 8.61696 19.7729Z"
            fill="#64BB46"
          />
        </mask>
        <g mask="url(#mask0_2155_11613)">
          <rect width="24" height="24" fill="#666C88" />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2155_11613"
          x1="5.77732"
          y1="15.1452"
          x2="5.77732"
          y2="2.67033"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.3" stop-color="#65BC46" />
          <stop offset="1" stop-color="#36C5ED" />
        </linearGradient>
        <clipPath id="clip0_2155_11613">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default KonfirSmallIcon;
