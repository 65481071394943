import { Avatar, Menu, MenuItem, Typography } from "@material-ui/core";
import classNames from "classnames";
import React, { FC, ReactNode, useState } from "react";

import ChevronDown from "@/icons/ChevronDown";
import ChevronUp from "@/icons/ChevronUp";
import KonfirSmallIcon from "@/icons/KonfirSmallIcon";
import SignOutIcon from "@/icons/SignOutIcon";
import UserIconOutlined from "@/icons/UserIconOutlined";

import styles from "./UserDropdown.module.css";

type UserDropdownProps = {
  children?: ReactNode;
  verifierFullName: string;
  verifierOrganisationName: string;
  onClickGoToKonsole: () => void;
  onClickLogout: () => void;
};

const UserDropdown: FC<UserDropdownProps> = ({
  verifierOrganisationName,
  verifierFullName,
  onClickLogout,
  onClickGoToKonsole,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const rootClasses = classNames(styles.root, {
    [styles.activeRoot]: !!anchorEl,
  });

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <div className={rootClasses} onClick={handleClick} data-testid="user-dropdown">
        <Avatar className={styles.avatar}>
          <UserIconOutlined />
        </Avatar>
        <div className={styles.textContainer}>
          <Typography title={verifierFullName} variant="body2" color="primary" className={styles.truncatedText}>
            {verifierFullName}
          </Typography>
          <Typography
            title={verifierOrganisationName}
            variant="subtitle1"
            color="secondary"
            className={styles.truncatedText}
          >
            {verifierOrganisationName}
          </Typography>
        </div>
        {Boolean(anchorEl) ? (
          <ChevronUp className={styles.chevronIcon} />
        ) : (
          <ChevronDown className={styles.chevronIcon} />
        )}
      </div>
      <Menu
        anchorEl={anchorEl}
        classes={{ paper: styles.menu }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem className={styles.menuItem} onClick={onClickGoToKonsole}>
          <KonfirSmallIcon />
          Go to Konsole
        </MenuItem>
        <MenuItem className={styles.menuItem} onClick={onClickLogout}>
          <SignOutIcon />
          Log out
        </MenuItem>
      </Menu>
    </>
  );
};

export default UserDropdown;
