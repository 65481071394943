import Image from "next/image";
import Link from "next/link";
import React, { FC, ReactNode } from "react";

import UserDropdown from "@/components/layouts/MainLayout/Header/UserDropdown/UserDropdown";
import { DropdownProps } from "@/hooks/useUserDropdown";

import styles from "./Header.module.css";

type HeaderProps = {
  children?: ReactNode;
  dropdownProps?: DropdownProps;
};

const Header: FC<HeaderProps> = ({ dropdownProps }) => {
  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <Link href="https://www.konfir.com/" className={styles.logoContainer}>
          <Image width="97" height="32" src="/about-us/konfir-logo.svg" alt="Konfir logo" />
        </Link>
        {!!dropdownProps && (
          <UserDropdown
            verifierOrganisationName={dropdownProps.verifierOrganisationName!}
            verifierFullName={dropdownProps.verifierFullName!}
            onClickGoToKonsole={dropdownProps.onClickGoToKonsole!}
            onClickLogout={dropdownProps.onClickLogout!}
          />
        )}
      </div>
    </div>
  );
};

export default Header;
